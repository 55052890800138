import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import pdf from "../files/Kurzusleírás_Elements of AI.pdf";
import Box from "../components/Box";

function HungaryPdf() {
  const [numPages, setNumPages] = useState(2);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="auto"
    >
      <Box py={5}>
        <a href={pdf} download target="_blank" rel="noopener noreferrer">
          Download PDF
        </a>
        {` `}
      </Box>
      <Document
        file={pdf}
        onLoadSuccess={onDocumentLoadSuccess}
        renderMode="svg"
        scale={2}
      >
        <Page pageNumber={1} />
        <Page pageNumber={2} />
      </Document>
    </Box>
  );
}

export default HungaryPdf;
